const breakpoint = 700;
const breakpointLarge = 1200;

export const Breakpoints = {
  Mobile: `(max-width: ${breakpoint - 1}px)`,
  Desktop: `(min-width: ${breakpoint}px)`,
  DesktopLarge: `(min-width: ${breakpointLarge}px)`,
};

const breakpointButton = 400;

export const ButtonBreakpoints = {
  Small: `(max-width: ${breakpointButton - 1}px)`,
  Large: `(min-width: ${breakpointButton}px)`,
};
