<div class="title-wrapper">
  <h3 class="title">{{ 'label.select.language' | translate }}:</h3>
  <button axButton icon-button (click)="close()">
    <ax-icon class="icon" name="close"></ax-icon>
  </button>
</div>
<mat-radio-group class="radio-group" [formControl]="selectedAlistLanguageForm">
  <ng-container *ngFor="let alistLanguage of (currentAlist$ | async)?.languages; let index = index">
    <mat-radio-button
      class="radio-button"
      [value]="alistLanguage.id"
      (click)="changeLanguage(alistLanguage)"
    >
      <span class="language">{{ alistLanguage.language | uppercase }}</span>
    </mat-radio-button>
    <lt-setting-button
      class="delete-button"
      icon="delete-outline"
      icon-button
      [spacing]="SettingButtonSpacing.MEDIUM"
      color="grey"
      [ariaLabel]="'label.delete' | translate"
      (click)="openDeleteLanguageModal(alistLanguage, index)"
    >
    </lt-setting-button>
  </ng-container>
</mat-radio-group>

<lt-setting-button
  *ngIf="canAddLanguages$ | async"
  class="add-button"
  icon="plus"
  [spacing]="SettingButtonSpacing.MEDIUM"
  color="primary"
  [ariaLabel]="'label.add' | translate"
  [postIcon]="''"
  (click)="add()"
>
  <span class="data">{{ 'label.add.language' | translate }}</span>
</lt-setting-button>
