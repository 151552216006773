import {FormService} from '@alist/helpers/services/form.service';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AlistLanguageRepresentation} from '@aztrix/sdk';
import {combineLatest, first} from 'rxjs';

@Component({
  selector: 'al-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent {
  @Input() alistLanguage: AlistLanguageRepresentation;
  @Input() index: number;

  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor(
    private _formService: FormService,
    private _aList: AListService
  ) {}

  confirmDelete() {
    this._formService.removeLanguage(this.index);
    combineLatest([this._aList.clientAlist$, this._aList.activeAlistLanguageId$])
      .pipe(first())
      .subscribe(([alist, activeAlistLanguageId]) => {
        if (
          activeAlistLanguageId === this.alistLanguage.id &&
          alist &&
          alist.languages?.length &&
          alist.languages[0].language
        ) {
          this._aList.setActiveAlistLanguageCode(alist.languages[0].language);
        }
      });
    this.confirm.emit();
  }

  cancelDelete() {
    this.cancel.emit();
  }
}
