import {AListService} from '@aztrix/list/store/alist/alist.service';
import {Component, ElementRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {LanguageSelectorModalComponent} from '@aztrix/components/language-selector-modal';
import {ModalSize, OverlayService} from '@aztrix/components/overlay';
import {TranslateService} from '@aztrix/translate';
import {SettingButtonSpacing} from '@aztrix/list';
import {AlistLanguageRepresentation} from '@aztrix/sdk';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineLatest, first, map, switchMap} from 'rxjs';

import {ConfirmDeleteComponent} from './confirm-delete/confirm-delete.component';
import {FormService} from '../../helpers/services/form.service';

@UntilDestroy()
@Component({
  selector: 'al-language-edit',
  templateUrl: './language-edit.component.html',
  styleUrls: ['./language-edit.component.scss'],
})
export class LanguageEditComponent {
  currentAlist$ = this._alist.clientAlist$;
  currentAlistLanguage$ = this._alist.clientAlistLanguage$;

  location = location;

  selectedAlistLanguageForm = new FormControl();

  alistLanguages$ = this.currentAlist$.pipe(
    map((alist) => alist?.languages?.map((alist) => alist.language))
  );

  possibleLanguages$ = this.alistLanguages$.pipe(
    map((alistLanguages) =>
      this._translateService
        .getAvailableLangs()
        .filter((language) => !alistLanguages?.includes(language))
    )
  );

  canAddLanguages$ = this.alistLanguages$.pipe(
    map((alistLanguages) => {
      const availableLangs = this._translateService.getAvailableLangs();
      return availableLangs.length !== alistLanguages?.length;
    })
  );

  activeAlistItems$ = this.currentAlistLanguage$.pipe(map((alistLanguage) => alistLanguage?.items));

  SettingButtonSpacing = SettingButtonSpacing;

  constructor(
    private _elementRef: ElementRef,
    private _alist: AListService,
    private _translateService: TranslateService,
    private _overlayService: OverlayService,
    private _formService: FormService
  ) {
    this.currentAlistLanguage$
      .pipe(untilDestroyed(this))
      .subscribe((alistLanguage) => this.selectedAlistLanguageForm.setValue(alistLanguage?.id));
  }

  add() {
    this.possibleLanguages$.pipe(first()).subscribe((languages) => {
      this._overlayService.closeModal();
      this._overlayService.createModal(this._elementRef, LanguageSelectorModalComponent, {
        modalSize: ModalSize.SMALL,
        init: (modal) => {
          modal.languageCodes = languages;
          combineLatest([
            this.currentAlistLanguage$.pipe(first()),
            modal.didSelectLanguage.pipe(first()),
          ])
            .pipe(
              switchMap(([alistLanguage, newLanguage]) => {
                const newAListLanguage: AlistLanguageRepresentation = {
                  ...alistLanguage,
                  id: undefined,
                  language: newLanguage,
                };
                return this._formService
                  .addLanguageForm(newAListLanguage)
                  .pipe(map(() => newAListLanguage));
              })
            )
            .subscribe((alistLanguage) => {
              this.changeLanguage(alistLanguage);
            });
        },
      });
    });
  }

  changeLanguage(language: AlistLanguageRepresentation) {
    if (language.language) {
      this._alist.setActiveAlistLanguageCode(language.language);
    }
    this._overlayService.closeModal();
  }

  openDeleteLanguageModal(alistLanguage: AlistLanguageRepresentation, index: number) {
    const modalRef = this._overlayService.createModal(this._elementRef, ConfirmDeleteComponent, {
      init: (modal) => {
        modal.alistLanguage = alistLanguage;
        modal.index = index;
        modal.confirm.subscribe(() => {
          this._overlayService.closeModal(modalRef);
          this._changeToFirstAvailableLanguage();
        });
        modal.cancel.subscribe(() => {
          this._overlayService.closeModal(modalRef);
        });
      },
    });
  }

  private _changeToFirstAvailableLanguage() {
    this.currentAlist$.pipe(first()).subscribe((alist) => {
      if (alist?.languages) {
        this.changeLanguage(alist.languages[0]);
      }
    });
  }

  close() {
    this._overlayService.closeModal();
  }
}
