<div class="title-wrapper">
  <h3 class="title">{{ 'label.select.page' | translate }}:</h3>
  <button axButton icon-button (click)="close()">
    <ax-icon class="icon" name="close"></ax-icon>
  </button>
</div>
<mat-radio-group class="radio-group" [formControl]="selectedAlistForm">
  <ng-container *ngFor="let alist of alists$ | async; let index = index">
    <mat-radio-button class="radio-button" [value]="alist.id" (click)="changeAlist(alist)">
      <span class="language" *ngIf="!alist.domainEnabled">
        {{ (small$ | async) ? '...' : (viewURI$ | async | lowercase) }}/{{ alist.name }}*
      </span>
      <span class="language" *ngIf="alist.domainEnabled">
        {{ alist.domain }}
      </span>
    </mat-radio-button>
    <div class="alist-actions">
      <lt-setting-button
        class="delete-button"
        icon="pencil"
        icon-button
        [spacing]="SettingButtonSpacing.MEDIUM"
        color="grey"
        [ariaLabel]="'label.edit' | translate"
        (click)="editAlist(alist)"
      ></lt-setting-button>
      <lt-setting-button
        class="delete-button"
        icon="delete-outline"
        icon-button
        [spacing]="SettingButtonSpacing.MEDIUM"
        color="grey"
        [ariaLabel]="'label.delete' | translate"
        (click)="deleteAlist(alist, index)"
      ></lt-setting-button>
    </div>
  </ng-container>
</mat-radio-group>

<lt-setting-button
  class="add-button"
  icon="plus"
  [spacing]="SettingButtonSpacing.MEDIUM"
  color="primary"
  [ariaLabel]="'label.add' | translate"
  [postIcon]="''"
  (click)="add()"
>
  <span class="data">{{ 'label.add.page' | translate }}</span>
</lt-setting-button>
