<p [innerHTML]="'page.delete.confirmation' | translate: {pageName: alist.name}"></p>
<div class="wrapper">
  <button class="button" axButton [disabled]="loading" (click)="cancel()">
    {{ 'label.cancel' | translate }}
  </button>
  <button
    class="button"
    axButton
    filled
    [disabled]="loading"
    color="primary"
    (click)="confirmDelete()"
  >
    {{ 'label.confirm' | translate }}
  </button>
</div>
