import {Breakpoints} from '@alist/breakpoint';
import {FormService} from '@alist/helpers/services/form.service';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, ElementRef} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {OverlayService} from '@aztrix/components/overlay';
import {EnvironmentService} from '@aztrix/environment';
import {AlistRealmEditComponent, SettingButtonSpacing} from '@aztrix/list';
import {AlistRepresentation, AlistsService} from '@aztrix/sdk';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineLatest, first, map, shareReplay, switchMap} from 'rxjs';

import {ConfirmDeleteComponent} from './confirm-delete/confirm-delete.component';

@UntilDestroy()
@Component({
  selector: 'al-alist-select',
  templateUrl: './alist-select.component.html',
  styleUrls: ['./alist-select.component.scss'],
})
export class AlistSelectComponent {
  alists$ = this._alist.clientAlists$;
  currentAlist$ = this._alist.clientAlist$;

  location = location;

  selectedAlistForm = new FormControl();

  viewURI$ = this._environment.string$('ALIST_VIEW_URI');

  small$ = this._breakpointObserver.observe(Breakpoints.Mobile).pipe(
    map(({matches}) => matches),
    shareReplay(1)
  );

  SettingButtonSpacing = SettingButtonSpacing;

  constructor(
    private _elementRef: ElementRef,
    private _alist: AListService,
    private _alists: AlistsService,
    private _overlayService: OverlayService,
    private _environment: EnvironmentService,
    private _formService: FormService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router
  ) {
    this.currentAlist$
      .pipe(untilDestroyed(this))
      .subscribe((alist) => this.selectedAlistForm.setValue(alist?.id));
  }

  add() {
    const modalRef = this._overlayService.createModal(this._elementRef, AlistRealmEditComponent, {
      init: (modal) => {
        modal.didConfirm
          .pipe(
            first(),
            switchMap((alist: AlistRepresentation) => {
              modal.loading$.next(true);
              return this._alists.createAlist({
                name: alist.name,
                domain: alist.domain,
                domainEnabled: alist.domainEnabled,
                active: false,
                languages: [{language: 'en'}],
              });
            }),
            switchMap((alist) => {
              return combineLatest([
                this._alist.setClientAlist(alist),
                this._alist.setServerAlist(alist),
              ]).pipe(map(() => alist));
            })
          )
          .subscribe((alist) => {
            this._overlayService.closeModal(modalRef);
            this.changeAlist(alist);
            this._formService.updateForm();
            modal.loading$.next(false);
          });
      },
    });
  }

  changeAlist(alist: AlistRepresentation) {
    this._router.navigate([], {
      queryParams: {list: alist.name},
      queryParamsHandling: 'merge',
    });
    this._alist.setActiveAlist(alist.id || '');
    this._overlayService.closeModal();
  }

  editAlist(alist: AlistRepresentation) {
    this._formService
      .alistForm$(alist.id || '')
      .pipe(first())
      .subscribe((alistForm) => {
        const modalRef = this._overlayService.createModal(
          this._elementRef,
          AlistRealmEditComponent,
          {
            init: (modal) => {
              modal.alist = alist;
              modal.didConfirm.pipe(first()).subscribe((alistEdit: AlistRepresentation) => {
                alistForm?.get('domainEnabled')?.setValue(alistEdit.domainEnabled);
                alistForm?.get('name')?.setValue(alistEdit.name);
                alistForm?.get('domain')?.setValue(alistEdit.domain);
                this._overlayService.closeModal(modalRef);
              });
            },
          }
        );
      });
  }

  deleteAlist(alist: AlistRepresentation, index: number) {
    this._overlayService.createModal(this._elementRef, ConfirmDeleteComponent, {
      init: (modal) => {
        modal.alist = alist;
        modal.index = index;
      },
    });
  }

  close() {
    this._overlayService.closeModal();
  }
}
