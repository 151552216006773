<p
  [innerHTML]="
    'page.language.delete.confirmation'
      | translate: {languageCode: alistLanguage.language | uppercase}
  "
></p>
<div class="wrapper">
  <button class="button" axButton (click)="cancelDelete()">{{ 'label.cancel' | translate }}</button>
  <button class="button" axButton filled color="primary" (click)="confirmDelete()">
    {{ 'label.confirm' | translate }}
  </button>
</div>
