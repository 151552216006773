import {FormService} from '@alist/helpers/services/form.service';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {Component, Input} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {AlistRepresentation, AlistsService} from '@aztrix/sdk';

@Component({
  selector: 'al-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent {
  @Input() alist: AlistRepresentation;
  @Input() index: number;

  loading = false;

  constructor(
    private _overlayService: OverlayService,
    private _formService: FormService,
    private _aList: AListService,
    private _aLists: AlistsService
  ) {}

  confirmDelete() {
    this.loading = true;
    this._aLists.deleteAlist(this.alist.id || '').subscribe(() => {
      this._aList.removeAlist(this.alist.id || '');
      this._formService.removeAlist(this.index);
      this._overlayService.closeModal();
      this.loading = false;
    });
  }

  cancel() {
    this._overlayService.closeModal();
  }
}
