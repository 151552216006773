import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';

import {UpgradeTierButtonModule} from '../upgrade-tier-button/upgrade-tier-button.module';
import {TierWarningComponent} from './tier-warning.component';

@NgModule({
  declarations: [TierWarningComponent],
  imports: [CommonModule, UpgradeTierButtonModule, ButtonModule, TranslatePipe],
  exports: [TierWarningComponent],
})
export class TierWarningModule {}
